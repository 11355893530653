export async function fetchJwt() {
  const response = await fetch(
    `${process.env.REACT_APP_IBL_LMS_URL}/ibl-auth/request-jwt/`,
    {
      method: 'GET',
      credentials: 'include', // Allow credentials
    }
  );
  const data = await response.json(); // Parse the JSON response
  return data; // Return the response data
}
