import {
  isRouteErrorResponse,
  RouterProvider,
  useRouteError,
  json,
  useLocation,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import '@iblai/ibl-web-react-common/index.css';
import { Logout } from './pages/Logout';
import './App.css';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import { AuthContext } from './utils/context';
import Login, { CompleteLogin } from './pages/Login';
import CreateOrganization from './pages/CreateOrganization';
import { areSubdomainsOfSameParentDomain } from './utils/utils';
import {
  GLOBAL_COLORS,
  inIframe,
  sentryCreateBrowserRouter,
  api,
  useIblTranslation,
  isJSON,
} from '@iblai/ibl-web-react-common';
import MobileSso from './pages/Login/MobileSso/MobileSso';
import './i18n';
import Home from './pages/Home/Home';
import { createGlobalStyle } from 'styled-components';
import { match } from 'react-router-dom';
import { IndexLayout } from './pages';

export const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      // return <Error404 />;
      return <div>404</div>;
    } else if (error.status === 403) {
      return <div>403</div>;
    }
  }
};

function App() {
  const [authData] = useState(() => ({ ...localStorage }));
  useIblTranslation();
  useEffect(() => {
    api.initiateAxdClientsWithToken(authData.axd_token);
    if (authData.axd_token && authData.dm_token) {
      window.parent.postMessage(
        {
          reason: 'TOKEN',
          message: {
            axdToken: {
              token: authData.axd_token,
              expires: authData.axd_token_expires,
            },
            dmToken: {
              token: authData.dm_token,
              expires: authData.dm_token_expires,
            },
            user: isJSON(authData) ? JSON.parse(authData.userData) : null,
            tenants: isJSON(authData) ? JSON.parse(authData.tenants) : null,
          },
        },
        '*'
      );
    } else if (inIframe()) {
      window.parent.postMessage(
        {
          reason: 'TOKEN',
          message: null,
        },
        '*'
      );
    }
    appReady();
  }, []);
  const appReady = () => {
    window.parent.postMessage(
      {
        reason: 'IFRAME_READY',
      },
      '*'
    );
  };
  return (
    <>
      <GlobalStyles />
      <AuthContext.Provider
        value={{
          ...authData,
          defaultNotificationMsg: new URLSearchParams(
            window.location.search
          ).get('notification-message'),
        }}
      >
        <RouterProvider
          router={sentryCreateBrowserRouter([
            {
              path: '/',
              errorElement: <ErrorPage />,
              element: <IndexLayout />,
              loader: async ({ params, request }) => {
                const url = new URL(request.url);
                const authDomain = url.hostname;
                if (url.searchParams.get('redirect-to')) {
                  const redirectToDomain = new URL(
                    url.searchParams.get('redirect-to')
                  ).hostname;
                  const acceptedRedirectOrigins =
                    process.env.REACT_APP_IBL_ACCEPTED_REDIRECT_ORIGINS ?? '';
                  if (
                    redirectToDomain &&
                    (areSubdomainsOfSameParentDomain(
                      authDomain,
                      redirectToDomain
                    ) ||
                      acceptedRedirectOrigins
                        .split(',')
                        .includes(redirectToDomain))
                  ) {
                    return [];
                  } else {
                    try {
                      const response = await fetch(
                        `${
                          process.env.REACT_APP_IBL_DM_URL
                        }/api/core/domains/whitelist/?url=${url.searchParams.get(
                          'redirect-to'
                        )}`
                      );
                      if (!response.ok) {
                        throw new Response(
                          JSON.stringify({ message: 'Unauthorized Redirect' }),
                          { status: 403 }
                        );
                      }
                      return [];
                    } catch (error) {
                      throw new Response(
                        JSON.stringify({ message: 'Unauthorized Redirect' }),
                        { status: 403 }
                      );
                    }
                  }
                }

                return [];
              },
              children: [
                {
                  path: '/',
                  element: <Home />,
                },
                {
                  path: 'login',
                  element: <Login />,
                },
                {
                  path: 'logout',
                  element: <Logout />,
                },
                {
                  path: 'mobile/login/complete',
                  element: <CompleteLogin isMobileApp={true} />,
                },
                {
                  path: 'mobile/sso',
                  element: <MobileSso />,
                },
                {
                  path: 'login/complete',
                  element: <CompleteLogin isMobileApp={false} />,
                },
                {
                  path: 'signup',
                  element: <Signup />,
                },
                {
                  path: 'forgot-password',
                  element: <ForgotPassword />,
                },
                {
                  path: 'reset-password',
                  element: <ResetPassword />,
                },
                {
                  path: 'create-organization',
                  element: <CreateOrganization />,
                },
              ],
            },
          ])}
        />
      </AuthContext.Provider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;
export default App;
