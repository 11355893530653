import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import {
  defaultSPALogoData,
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick,
  handleTextHelper,
  removeSpaceFromText,
  resetLoginForm,
  stringsAreSimilar,
  validateEmail,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';
import { api } from '@iblai/ibl-web-react-common';
import FreeTrial from '../../components/FreeTrial/FreeTrial';
import MfeContextUI from '../../components/MfeContextUI/MfeContextUI';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import StaticHeader from '../../components/StaticHeader/StaticHeader';
import { checkIfUserIsInvited, checkIfUserIsLicensed } from './utils';
import { PasswordsScreen } from './passwords-screen';
import { EmailUsernameScreen } from './email-username-screen';

const ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'This field is required',
  EMAIL_INVALID: 'Must be a valid email',
  USERNAME_REQUIRED: 'This field is required',
  USERNAME_INVALID: 'Must be more than 4 and less than 32 characters',
  USERNAME_ALPHANUMERIC: 'Must be alphanumeric only',
  PASSWORD_REQUIRED: 'This field is required',
  PASSWORD_INVALID: 'Must be more than 8 characters',
  PASSWORD_SIMILAR_EMAIL: 'Password too similar to email address',
  PASSWORD_SIMILAR_USERNAME: 'Password too similar to username',
  CONFIRM_PASSWORD_REQUIRED: 'This field is required',
  CONFIRM_PASSWORD_MATCH: 'Must match the password field',
};

const Signup = () => {
  const [notifications, notify, onClose] = useNotification();
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState(null);
  const emailExample = process.env.REACT_APP_IBL_EMAIL_EXAMPLE;
  const checkInviteBeforeRegistration =
    process.env.REACT_APP_IBL_CHECK_USER_INVITE_BEFORE_REGISTRATION === 'true';
  const checkLicenseBeforeRegistration =
    process.env.REACT_APP_IBL_CHECK_USER_LICENSE_BEFORE_REGISTRATION === 'true';

  // email form state
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  // username form state
  const [username, setUsername] = useState('');
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');

  // password form state
  const [password, setPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  // confirm password form state
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPasswordError, setShowConfirmPasswordError] =
    useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState('');

  // form state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPasswordScreen, setShowPasswordScreen] = useState(false);

  const [isDataCollectionConsentChecked, setIsDataCollectionConsentChecked] =
    useState(false);

  const setConsentInLocalStorage = () => {
    if (process.env.REACT_APP_IBL_ENABLE_CONSENT_FORM === 'true') {
      localStorage.setItem(
        'consented_to_data_collection',
        isDataCollectionConsentChecked
      );
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const _redirectTo = queryParams.get('redirect-to');

    setRedirectTo(_redirectTo);
    localStorage.setItem('redirectTo', _redirectTo);
  }, []);

  const handleResetForm = () => {
    setEmail('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setIsSubmitting(false);
    setShowEmailError(false);
    setShowUsernameError(false);
    setEmailErrorMessage('');
    setUsernameErrorMessage('');
  };

  const checkIfUserCanRegister = (
    checkFn,
    email,
    successCallback,
    errorCallback
  ) => {
    checkFn(email, successCallback, errorCallback);
  };

  const handleSignupSubmit = ({
    email,
    username,
    password,
    handleResetForm,
    notify,
  }) => {
    if (checkInviteBeforeRegistration || checkLicenseBeforeRegistration) {
      let checkFn;
      let registrationCheckSuccessMsg = '';
      if (checkInviteBeforeRegistration) {
        checkFn = checkIfUserIsInvited;
        registrationCheckSuccessMsg = t(
          `You haven't been previously invited by an admin to register. Please contact an admin & try again!`
        );
      } else if (checkLicenseBeforeRegistration) {
        checkFn = checkIfUserIsLicensed;
        registrationCheckSuccessMsg = t(
          `You haven't been previously licensed by an admin to register. Please contact an admin & try again!`
        );
      }
      checkIfUserCanRegister(
        checkFn,
        email,
        (statusCode) => {
          if (statusCode === 200) {
            handleSignupAPI({
              email,
              username,
              password,
              handleResetForm,
              notify,
            });
          } else {
            //400 User isn't previously invited so can't register
            handleResetForm();
            notify(
              t('Registration not allowed!'),
              registrationCheckSuccessMsg,
              'error'
            );
          }
        },
        () => {
          handleResetForm();
          notify(
            t('User registration status check failed!'),
            t(
              `Error occurred while checking if new user can signup. Please try again!`
            ),
            'error'
          );
        }
      );
    } else {
      handleSignupAPI({
        email,
        username,
        password,
        handleResetForm,
        notify,
      });
    }
  };

  const handleSignupAPI = ({
    email,
    username,
    password,
    handleResetForm,
    notify,
  }) => {
    const userData = new FormData();
    userData.append('email', email);
    userData.append('username', username);
    userData.append('password', password);
    userData.append('is_authn_mfe', true);
    userData.append('name', username);
    userData.append('honor_code', true);

    setConsentInLocalStorage();

    api.iblwebauth.registerUser(
      userData,
      (data) => {
        window.location.href = `${window.location.origin}/login/complete`;
      },
      (error) => {
        handleResetForm();
        const errResponse = error.responseJSON;
        if (errResponse.error_code === 'duplicate-email-username') {
          const { username, email } = errResponse;
          notify(
            'Email and username are already in use!',
            `${username[0].user_message}${
              !String(username[0].user_message).endsWith('.') ? '.' : ''
            }\n${email[0].user_message}${
              !String(email[0].user_message).endsWith('.') ? '.' : ''
            }`,
            'error'
          );
        } else if (errResponse.error_code === 'duplicate-email') {
          const { email } = errResponse;
          notify(
            'Email is already in use!',
            `${email[0].user_message}${
              !String(email[0].user_message).endsWith('.') ? '.' : ''
            }`,
            'error'
          );
        } else if (errResponse.error_code === 'duplicate-username') {
          const { username } = errResponse;
          notify(
            'Username is already in use!',
            `${username[0].user_message}${
              !String(username[0].user_message).endsWith('.') ? '.' : ''
            }`,
            'error'
          );
        }
      }
    );
  };

  function navigateToPasswordScreen() {
    setEmailErrorMessage('');
    setUsernameErrorMessage('');
    setShowEmailError(false);
    setShowUsernameError(false);

    // check if email is entered
    if (!email || email.length === 0) {
      setEmailErrorMessage(ERROR_MESSAGES.EMAIL_REQUIRED);
      setShowEmailError(true);
      return;
    }

    // check if email is valid
    if (!validateEmail(email)) {
      setEmailErrorMessage(ERROR_MESSAGES.EMAIL_INVALID);
      setShowEmailError(true);
      return;
    }

    // check if username is entered
    if (!username || username.length === 0) {
      setUsernameErrorMessage(ERROR_MESSAGES.USERNAME_REQUIRED);
      setShowUsernameError(true);
      return;
    }

    // check if username is valid
    if (username.length < 4 || username.length > 32) {
      setUsernameErrorMessage(ERROR_MESSAGES.USERNAME_INVALID);
      setShowUsernameError(true);
      return;
    }

    if (!String(username).match(/^[a-zA-Z0-9]+$/)) {
      setUsernameErrorMessage(ERROR_MESSAGES.USERNAME_ALPHANUMERIC);
      setShowUsernameError(true);
      return;
    }

    setShowPasswordScreen(true);
  }

  const formSubmit = (formEvent) => {
    formEvent.preventDefault();

    if (!showPasswordScreen) {
      navigateToPasswordScreen();
      return;
    }

    // validate password
    if (!password || password.length === 0) {
      setPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_REQUIRED);
      setShowPasswordError(true);
      return;
    }

    if (password.length < 8) {
      setPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_INVALID);
      setShowPasswordError(true);
      return;
    }

    if (stringsAreSimilar(password, email.split('@')[0])) {
      setPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_SIMILAR_EMAIL);
      setShowPasswordError(true);
      return;
    }

    if (stringsAreSimilar(password, username)) {
      setPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_SIMILAR_USERNAME);
      setShowPasswordError(true);
      return;
    }

    // validate confirm password
    if (!confirmPassword || confirmPassword.length === 0) {
      setConfirmPasswordErrorMessage(ERROR_MESSAGES.CONFIRM_PASSWORD_REQUIRED);
      setShowConfirmPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage(ERROR_MESSAGES.CONFIRM_PASSWORD_MATCH);
      setShowConfirmPasswordError(true);
      return;
    }

    setIsSubmitting(true);

    handleSignupSubmit({
      email,
      username,
      password,
      handleResetForm,
      notify,
    });

    return;
  };

  const STATIC_HEADER_ENABLED =
    process.env.REACT_APP_IBL_STATIC_HEADER_ENABLED === 'true';
  const USERNAME_LOWERCASE_DISABLED =
    process.env.REACT_APP_IBL_USERNAME_INPUT_LOWERCASED_DISABLED === 'true';

  return (
    <>
      <div className="w-layout-vflex auth-page-container">
        {STATIC_HEADER_ENABLED && <StaticHeader />}
        <FreeTrial />
        <div className="w-layout-vflex auth-page-block">
          {!STATIC_HEADER_ENABLED && (
            <img
              src={defaultSPALogoData.logo}
              loading="lazy"
              alt=""
              className="auth-logo-img"
            />
          )}
          <div className="w-layout-vflex auth-page-main-content">
            <h2 className="auth-page-title">{t('Create an Account')}</h2>
            <div className="auth-title-desc">
              {t('Email verification may be required.')}
            </div>
            <div className="auth-form-block w-form">
              <form className="auth-form" onSubmit={formSubmit}>
                {showPasswordScreen ? (
                  <PasswordsScreen
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    password={password}
                    setPassword={setPassword}
                    showPasswordError={showPasswordError}
                    passwordErrorMessage={passwordErrorMessage}
                    confirmPasswordErrorMessage={confirmPasswordErrorMessage}
                    showConfirmPasswordError={showConfirmPasswordError}
                    t={t}
                  />
                ) : (
                  <EmailUsernameScreen
                    email={email}
                    setEmail={setEmail}
                    showEmailError={showEmailError}
                    emailErrorMessage={emailErrorMessage}
                    emailExample={emailExample}
                    username={username}
                    setUsername={setUsername}
                    showUsernameError={showUsernameError}
                    usernameErrorMessage={usernameErrorMessage}
                  />
                )}
                <button
                  type="submit"
                  className={`w-layout-hflex auth-submit-btn ${
                    isSubmitting ? 'disabled' : ''
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="auth-submit-btn-label">Submitting...</div>
                  ) : (
                    <>
                      <div className="auth-submit-btn-label">
                        {t('Continue')}
                      </div>
                      <div className="btn-spinner-block hidden w-embed">
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </>
                  )}
                </button>
              </form>
              <div className="w-form-done">
                <div>{t('Thank you! Your submission has been received!')}</div>
              </div>
              <div className="w-form-fail">
                <div>
                  {t('Oops! Something went wrong while submitting the form.')}
                </div>
              </div>
            </div>
            {process.env.REACT_APP_IBL_ENABLE_CONSENT_FORM === 'true' && (
              <div className="data-collection-form" role="group">
                <input
                  type="checkbox"
                  id="dataCollectionOptIn"
                  name="dataCollectionOptIn"
                  checked={isDataCollectionConsentChecked}
                  onChange={(event) =>
                    setIsDataCollectionConsentChecked(event.target.checked)
                  }
                  aria-invalid="false"
                />
                <div>
                  <label
                    className="text-block-31"
                    htmlFor="dataCollectionOptIn"
                    style={{ fontWeight: 'inherit' }}
                  >
                    We are requesting your consent to use your information for
                    anonymised research aimed at improving the course and
                    studying its impact. For more information, please see our{' '}
                    <a
                      href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    .
                  </label>
                </div>
              </div>
            )}

            <div className="w-layout-hflex auth-redirect-block">
              <div className="text-block-31">
                {t('Already have an account?')}
              </div>
              <Link
                to={`/login?redirect-to=${redirectTo}`}
                className="auth-link-redirect"
              >
                {t('Log In')}
              </Link>
            </div>
            <MfeContextUI />
          </div>
          <Footer />
        </div>
      </div>
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
    </>
  );
};

export default Signup;
