import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { setCommonCookie, setVisitedCookie } from '../../../utils/utils';
import { IBLSpinLoader, Error, api, isJSON } from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';
import LZString from 'lz-string';
import { fetchJwt } from '../../../api';

const DELAY_RETRY = 2000;

const CompleteLogin = ({ isMobileApp }) => {
  const MAX_RETRY = 10;
  const location = useLocation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [retry, setRetry] = useState(0);
  const intervalId = useRef(null);
  const { t } = useTranslation();
  const [errorHeader, setErrorHeader] = useState(t("Can't authenticate you"));
  const [errorMessage, setErrorMessage] = useState(
    t("We are sorry, we can't verify your identity")
  );

  useEffect(() => {
    setVisitedCookie();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (isMobileApp) {
      // return to mobile app with the authorization code
      window.location.href = `ibl-apps://${
        window.location.hostname
      }?code=${queryParams.get('code')}`;
    } else {
      api.ibledxplatform.getCsrfToken(1440);
      const logoutDmAxd = async () => {
        await fetch(
          `${process.env.REACT_APP_IBL_DM_URL}/api/core/session/logout/`,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.dm_token}`,
            },
          }
        );
      };
      if (localStorage.dm_token) {
        logoutDmAxd();
      }
      if (queryParams.has('tenant')) {
        localStorage.setItem('selected_tenant', queryParams.get('tenant'));
      }
      if (queryParams.has('redirect-to')) {
        localStorage.setItem('redirectTo', queryParams.get('redirect-to'));
      }

      api.ibledxtenants.getUserTenants(
        (tenants) => {
          const selectedTenant = localStorage.getItem('selected_tenant');
          api.iblutils.saveUserTenantsDataToLocalStorage(
            tenants,
            selectedTenant
          );
          if (tenants.length) {
            const formData = new FormData();

            formData.append('platform_key', localStorage.getItem('tenant'));
            api.ibldmauth.getToken(
              formData,
              async ({ data }) => {
                api.iblwebauth.initializeLocalStorageWithAuthData(
                  data.axd_token,
                  data.dm_token,
                  data.user,
                  tenants,
                  localStorage.getItem('tenant')
                );
                let redirectTo = localStorage.getItem('redirectTo');
                const redirectToken = localStorage.getItem('redirectToken');
                if (
                  process.env.REACT_APP_IBL_AUTH_EDX_USE_JWT === 'true' &&
                  !localStorage.edx_jwt_token
                ) {
                  // Expectation here is it's a SSO login via google for example,
                  // Hence we'll need to fetch a JWT token since it's configured
                  try {
                    const jwtResponse = await fetchJwt();
                    localStorage.setItem('edx_jwt_token', jwtResponse.token);
                  } catch (error) {
                    console.error(
                      `Auth configured to use JWT but can't fetch JWT ${error}`
                    );
                  }
                }
                if (redirectTo || redirectToken) {
                  setCommonCookie('userData', localStorage.userData);
                  setCommonCookie('currentTenant', localStorage.current_tenant);
                  let {
                    axd_token,
                    axd_token_expires,
                    userData,
                    tenants,
                    redirectTo,
                    dm_token_expires,
                    tenant,
                    dm_token,
                    edx_jwt_token,
                    consented_to_data_collection,
                  } = { ...localStorage };
                  const userObject = {
                    axd_token,
                    axd_token_expires,
                    userData,
                    dm_token_expires,
                    tenant,
                    dm_token,
                    edx_jwt_token,
                  };
                  if (
                    process.env.REACT_APP_IBL_ENABLE_CONSENT_FORM === 'true' &&
                    consented_to_data_collection
                  ) {
                    userObject.consented_to_data_collection =
                      consented_to_data_collection;
                  }
                  localStorage.removeItem('selected_tenant');
                  const redirectPath = localStorage.getItem('redirectPath');
                  if (redirectToken) {
                    const seperator = redirectPath.includes('?') ? '&' : '?';
                    api.ibldmcore.getRedirectUrlFromToken(
                      { org: tenant, redirectToken },
                      (data) => {
                        const croppedTenants = JSON.parse(tenants).map(
                          (tenant) => ({
                            key: tenant.key,
                            name: tenant.name,
                            is_admin: tenant.is_admin,
                            username: tenant.username,
                          })
                        );
                        userObject['tenants'] = JSON.stringify(croppedTenants);

                        window.location.href = `${
                          data['url']
                        }${redirectPath}${seperator}ibl-data=${encodeURIComponent(
                          JSON.stringify(userObject)
                        )}`;
                        localStorage.removeItem('redirectToken');
                        localStorage.removeItem('redirectPath');
                      },
                      (error) => {
                        setErrorHeader(t('Unknown site'));
                        setErrorMessage(
                          t("We couldn't fetch the redirect url")
                        );
                      }
                    );
                  } else {
                    const tenantCreationData = localStorage.getItem(
                      'returningCustomerData'
                    );
                    localStorage.removeItem('returningCustomerData');
                    if (isJSON(tenantCreationData)) {
                      const queryParam = new URLSearchParams(
                        JSON.parse(tenantCreationData)
                      ).toString();
                      window.location.href = `${redirectTo}?${queryParam}`;
                    } else {
                      if (
                        process.env.REACT_APP_IBL_MULTITENANCY_ENABLED ===
                        'true'
                      ) {
                        const tenantKey = localStorage.getItem('tenant');
                        fetch(
                          `${process.env.REACT_APP_IBL_DM_URL}/api/core/orgs/${tenantKey}/metadata/`
                        )
                          .then((response) => response.json())
                          .then((data) => {
                            const spa =
                              data?.metadata?.spa_domains[
                                queryParams.get('app')
                              ];
                            if (spa?.active && spa?.domain) {
                              redirectTo = `https://${spa.domain}`;
                            }
                          })
                          .catch((error) =>
                            console.error(
                              '############# Error fetching domain status:',
                              error
                            )
                          )
                          .finally(() => {
                            window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
                              JSON.stringify(userObject)
                            )}`;
                          });
                      } else {
                        window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
                          JSON.stringify(userObject)
                        )}`;
                      }
                    }
                  }
                } else {
                  window.location.replace('/');
                }
              },
              (_) => {
                const _errorMsg = t(
                  "We couldn't fetch your tokens. Please contact administrator"
                );
                console.error(_errorMsg);
                errorCallback();
              }
            );
          } else {
            const _errorMsg = t(
              'Your account is not associated with any tenant. Please contact adminstration'
            );
            console.error(_errorMsg);
            errorCallback();
          }
        },
        (_) => {
          const _errorMsg = t(
            "We couldn't fetch your tenants. Please contact adminstrator"
          );
          console.error(_errorMsg);
          errorCallback();
        }
      );
    }
  }, [retry]);

  const errorCallback = () => {
    if (intervalId.current) {
      clearInterval(intervalId);
    }
    if (retry > MAX_RETRY) {
      setIsError(true);
    } else {
      intervalId.current = setTimeout(() => {
        setRetry(retry + 1);
      }, DELAY_RETRY);
    }
  };

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {!isError ? (
          <IBLSpinLoader size={40} containerHeight={'100px'} />
        ) : (
          <Error
            errorCode={500}
            header={errorHeader}
            message={errorMessage}
            subMessage={t(
              'If you believe this is a mistake, please message at'
            )}
          >
            <a
              href={'mailto:' + process.env.REACT_APP_IBL_SUPPORT_EMAIL}
              style={{ textDecoration: 'none' }}
            >
              &nbsp;{process.env.REACT_APP_IBL_SUPPORT_EMAIL}
            </a>
          </Error>
        )}
      </div>
    </>
  );
};

export { CompleteLogin };
