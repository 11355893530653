import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearCookies } from '@iblai/ibl-web-react-common';
import { setVisitedCookie } from '../../utils/utils';
const Logout = () => {
  const location = useLocation();

  const handleLogout = (redirectTo) => {
    const logoutCalls = [
      fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout/`, {
        method: 'GET',
        credentials: 'include',
      }),
      fetch(`${process.env.REACT_APP_IBL_STUDIO_URL}/logout/`, {
        method: 'GET',
        credentials: 'include',
      }),
      fetch(`${process.env.REACT_APP_IBL_DM_URL}/api/core/session/logout/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.dm_token}`,
        },
      }),
    ];
    if (process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL) {
      logoutCalls.push(
        fetch(`${process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL}`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.logout_url) {
              fetch(data.logout_url, {
                method: 'GET',
                credentials: 'include',
              });
            }
          })
      );
    }
    Promise.allSettled(logoutCalls).then(() => {
      localStorage.clear();
      const visited = document.cookie.includes('visited=true');
      clearCookies();
      if (visited) {
        setVisitedCookie();
      }
      window.location.href = redirectTo;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect-to');
    const tenant = queryParams.get('tenant');

    if (
      tenant &&
      localStorage.getItem('tenant') &&
      tenant !== localStorage.getItem('tenant')
    ) {
      const {
        axd_token,
        axd_token_expires,
        userData,
        dm_token_expires,
        tenant,
        dm_token,
        tenants,
        edx_jwt_token,
      } = { ...localStorage };

      const userObject = {
        axd_token,
        axd_token_expires,
        userData,
        dm_token_expires,
        tenant,
        dm_token,
        edx_jwt_token,
      };
      userObject['tenants'] = JSON.stringify(
        JSON.parse(tenants).map((tenant) => ({
          key: tenant.key,
          name: tenant.name,
          is_admin: tenant.is_admin,
          username: tenant.username,
        }))
      );
      window.location.href = redirectTo;
      return;
    }
    handleLogout(redirectTo);
  }, []);

  return <></>;
};

export { Logout };
